<template>
  <model ref="model">
    <div class="club-encyclopedia">
      <div class="title">{{ config.title }}</div>
      <img class="img" :src="config.imgUrl"/>
      <div class="desc">{{ config.desc }}</div>
      <div @click="copyPassword" class="btn">{{ config.btnText }}</div>
      <a :href="config.linkUrl" class="link">{{ config.linkText }} <van-icon name="arrow"/></a>
    </div>
  </model>
</template>

<script>
import Model from '../model/model.vue'
import {configV2Api} from "../../api/v2/configV2Api";
import {mapGetters} from "vuex";
import {copyToClipBoard} from "../../config/util";
import 'vant/lib/toast/index.css'
import {Toast} from "vant";

export default {
  components: {Model},
  computed: {
    ...mapGetters(['userVip', 'userInfo'])
  },
  data() {
    return {
      hasInit: false,
      config: {
        title: '',
        desc: '',
        btnText: '',
        password: '',
        linkText: '',
        linkUrl: '',
        imgUrl: ''
      }
    }
  },

  mounted() {
    if (this.$route.query.encyclopedia === 'true') {
      this.show()
    }
  },

  methods: {
    async show() {
      if (!this.hasInit) {
        await this.loadConfig()
        this.hasInit = true
      }
      this.$refs.model.show()
    },

    hide() {
      this.$refs.model.hide()
    },


    async loadConfig() {
      let [config] = await configV2Api.findByCodes(['CLUB_VIP_ENCYCLOPEDIA'])
      config = config || {}
      this.config = JSON.parse(config.value || '{}')
    },


    copyPassword() {
      if (!this.userInfo || !this.userInfo.id) {
        this.hide()
        return this.$store.dispatch('showLoginDialog')
      }
      if ((this.userVip.vipRightId + '') !== '5') {
        Toast('本权益为俱乐部会员专享')
        this.hide()
        this.$emit('needClubVip')
        return
      }

      copyToClipBoard(this.config.password)
      this.$toast.success('复制成功')
    }

  }
}
</script>

<style lang="less" scoped>
.club-encyclopedia {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.club-encyclopedia .title {
  font-size: 20px;
  color: #151D36;
  font-weight: bolder;
}

.club-encyclopedia .img {
  width: 80%;
  object-fit: contain;
  margin: 16px;
}

.club-encyclopedia .desc {
  font-size: 14px;
  color: #444;
  width: 80%;
}

.club-encyclopedia .btn {
  margin: 16px 0 8px 0;
  height: 45px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #151D36;
  color: #fff;
  font-size: 15px;
}

.club-encyclopedia .link {
  color: rgba(14, 143, 251, 1);
  font-size: 12px;
  display: flex;
  align-items: center;
}
</style>

<style>
.van-toast {
  z-index: 9999 !important;
}
</style>
